import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"

const CookieRegulation = ({ location }) => {
  const title = "Cookie規制でリターゲティング広告が打てなくなる？"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="Cookie規制で変わるリターゲティング広告とマーケ代替案"
          pagedesc="マーケティング担当者がチェックすべきCookie規制への代替施策とは？システムの仕組みを解説し、代わりに実施すべきデジタルマーケティング施策をご紹介します。変化に対応し、販促につながるSNSマーケのおすすめも。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/cookie-regulation.jpg"
          pagepath="/blog/cookie-regulation"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は10分で読むことができます
            </div>
            <h1>Cookie規制でリターゲティング広告が打てなくなる？</h1>
            <div className="blog__sns-container">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
             {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">公開日：2022年9月12日</time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="ノートパソコンの画面に表示されているダッシュボード画面"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/cookie-regulation.jpg"
                className="w-full"
              />
            </figure>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    そもそもCookieとは？
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id2" className="toc__link">
                    Cookieの種類
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id3" className="toc__link">
                    サードパーティーCookieとリターゲティング広告の関連性
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id4" className="toc__link">
                    ここ数年のCookie規制の動き
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id5" className="toc__link">
                    リターゲティング広告に代わるマーケティング施策は？
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id6" className="toc__link">
                    PARKLoTでは無料カウンセリングも行っています！
                  </a>
                </li>
              </ol>
            </div>
            <div>
              <p>こんにちは、PARKLoTマーケティング担当の冨士原萌野です。</p>
              <p>
                最近、Webサイトを閲覧していると「Cookieを許可しますか？」といったポップアップがよく表示されますよね。
              </p>
              <p>
                個人情報保護の観点から、ここ数年Cookieで取得する情報の取り扱いに関して規制が厳しくなっています。
              </p>
              <p>
                Cookie規制が進むとwebマーケティング施策に影響を与え、リターゲティング広告が実施できなくなると言われています。これはシステム的な側面から考えると、具体的にはどういうことでしょうか。
              </p>
            </div>
            <div>
              <h2 id="id1">そもそもCookieとは？</h2>
              <p>
                PC・スマートフォンなどのデバイスで、Webサイトへアクセスした際に作成されるテキストファイルのことです。デバイスの各ブラウザ（Chrome、Safari等）に保存されます。
              </p>
              <p>一般的には、例えばログイン情報の保持に使用されます。</p>
              <p>
                メールアドレスとパスワードを入力して、あるECサイトにログインしたとします。
              </p>
              <p>
                <br />
                その際のCookie使用の流れは以下になります。
              </p>
              <ol className="blog__border-list">
                <li>webサイト（webサーバー）でSession*が発行される。</li>
                <li>発行されたSessionをwebサイト（webサーバー）が保持する。</li>
                <li>
                  2と同時に、HTTPレスポンスでそのSessionに対応する値が返ってくるので、ブラウザ側はその値をCookieにセットする。
                  <br />
                  （セキュリティの観点から、発行されたSessionが暗号化された後返却されるので、発行されたSessionと返される値は一致しない）
                </li>
                <li>
                  以後そのECサイトに、ログイン成功時のブラウザでアクセスする場合、毎回必ずCookieの情報が送信されるので、ログインが保持される。
                </li>
                <li>
                  SessionもしくはCookieの有効期限が切れるまで、ログイン状態は保持される。
                </li>
              </ol>
              <p>
                Cookieとはこのように、Web上で「継続的な情報のやりとりを可能にする仕組み」の一つです。
              </p>
              <p>
                <br />
                *Session : webサイト（webサーバー）で発行される情報
              </p>
              <a
                href="https://qiita.com/hththt/items/07136ad74127999df271"
                target="_blank"
                rel="noreferrer"
              >
                参考:Cookieとセッションをちゃんと理解する - Qiita
              </a>
            </div>
            <div>
              <h2 id="id2">Cookieの種類</h2>
              <p>
                Cookieには<strong>ファーストパーティーCookie</strong>と
                <strong>サードパーティーCookie</strong>の2種類があります。
              </p>
            </div>
            <div>
              <h3>ファーストパーティーCookieとは</h3>
              <p>
                ユーザーがアクセスしたURLのドメインから発行される Cookieです。
              </p>
              <p>
                前段の「Cookieとは？」の例の「ログイン状態」を保持するために使用するCookieはこのファーストパーティーCookieに相当します。
              </p>
            </div>
            <div>
              <h3>サードパーティーCookieとは</h3>
              <p>
                ユーザーがアクセスしていないURLのドメインから発行されるCookieです。
              </p>
              <p>
                Cookie規制で規制の対象になっているCookieはこのサードパーティーCookieになります。
              </p>
            </div>
            <div>
              <p>
                <br />
                具体的にどのように使用されるか、リターゲティング広告との関連性を次段で説明します。
              </p>
            </div>
            <div>
              <h2 id="id3">
                サードパーティーCookieとリターゲティング広告の関連性
              </h2>
              <p>まず、3者の企業が存在します。</p>
              <p>
                A社 : web広告企業（リターゲティング広告を配信する企業）
                <br />
                B社 :
                広告出稿元企業（自社のリターゲティング広告を配信したい企業）
                <br />
                C社 :
                広告出稿先企業（他社のリターゲティング広告を自社のwebサイトに掲載したい企業）
              </p>
              <p>A社は1社のみ、B社、C社はそれぞれ複数企業を想定しています。</p>
              <p>
                <br />
                流れは以下になります。
              </p>
              <ol className="blog__border-list">
                <li>
                  自社の広告を配信したいB社、広告を掲載して利益を得たいC社は、それぞれA社と契約する
                </li>
                <li>
                  A社は、C社のwebサイトに、A社のコンテンツを埋め込む（A社のドメインが記載されている）
                  <p>
                    A社のコンテンツとは、C社のwebサイトにアクセスがあった際に、アクセスしたユーザーのブラウザにA社のドメインから発行されるCookieを埋め込む処理のことです。
                  </p>
                </li>
                <li>
                  ユーザーがC社①のwebサイトにアクセスすると、A社のサーバーに同時にアクセスされ、ユーザーのブラウザにA社のドメインで発行された値がCookieにセットされる。
                  <p>
                    ユーザーはA社のドメインには自発的にアクセスしていないので、ここでユーザーのブラウザに埋め込まれるCookieが、規制対象のサードパーティーCookieになります。
                  </p>
                  <p>
                    A社のサーバーには、例えばCookieの値が1のユーザーが、C社①のwebサイトにいつアクセスしたか、といった情報が保存されます。
                  </p>
                </li>
                <li>
                  ユーザーがまた他のC社②のwebサイトにアクセスした際に、3で発行されたCookieがAに自動的に送信される
                  <p>
                    A社のサーバーには、Cookieの値が1のユーザーが、C社①、C社②のwebサイトにいつアクセスした、といった情報が保存されます。
                  </p>
                </li>
                <li>
                  4を繰り返すことにより、A社のサーバーにそのユーザーのweb閲覧履歴が蓄積される
                </li>
                <li>
                  そのユーザーのweb閲覧履歴から、購入が見込まれそうなB社の広告が、C社のwebサイトに掲載される
                </li>
              </ol>
              <p>
                <br />
                つまりリターゲティング広告は、ユーザーのwebサイト訪問履歴が、ユーザー本人の意思とは関係なく、知らず知らずのうちに共有されることによって成り立っています。
              </p>
              <a
                href="https://www.soumu.go.jp/main_content/000599872.pdf"
                target="_blank"
                rel="noreferrer"
              >
                参考:オンライン広告におけるトラッキングの現状とその法的考察 -
                総務省
              </a>
            </div>
            <div>
              <h2 id="id4">ここ数年のCookie規制の動き</h2>
              <p>
                Cookie規制はブラウザでの規制と、法律での規制の両面から進められています。
              </p>
            </div>
            <div>
              <h3>ブラウザ</h3>
              <p>Safari : 2020年3月からサードパーティCookieを完全に廃止。</p>
              <p>
                Chrome :
                2020年1月に「2年以内にサードパーティCookie」を廃止を発表。
                <br />
                （2度目の延期発表で2024年末まで延期）
              </p>
            </div>
            <div>
              <h3>法律</h3>
              <p>
                EU :
                2018年5月からGDPR（EU一般データ保護規則）が施行。EU国内でCookieを取得する際にユーザーの同意を必須にする。
              </p>
              <p>
                アメリカ :
                2020年1月からCCPA（カリフォルニア州消費者プライバシー法）が施行。
                <br />
                カリフォルニア州の住民の個人情報に関して、Cookieを取得する際に、ユーザーの同意を必須にする。
              </p>
              <p>
                日本 : 2022年4月から改正個人情報保護法が施行。
                Cookieを他の情報と紐づけて、個人を特定するような運用を行っている場合は、ユーザーに対して同意を必須とする。
              </p>
              <p>
                <br />
                Cookieを使用した個人情報の取得は一層難しくなっています。
              </p>
            </div>
            <div>
              <h2 id="id5">
                リターゲティング広告に代わるマーケティング施策は？
              </h2>
              <p>
                デジタルマーケティングで重要な役割を果たしていたCookieに代わって、企業が自ら顧客の一次データを集め、施策を行って行く必要が生じています。
              </p>
              <p>
                <br />
                SNSを使用すると
              </p>
              <ul className="blog__border-list">
                <li>Cookie規制の影響を受けない</li>
                <li>購買層を直接的に狙ったキャンペーンが行える</li>
                <li>
                  リアルな購買層のデータが入手できる（キャンペーン各参加者のtwitterフォロワー数など）
                </li>
                <li>同時にマス層に向けても商品の認知を高められる</li>
              </ul>
              <p>など、低予算でもターゲット顧客の把握が可能です。</p>
              <p>またもちろんのこと自社商品の宣伝、購買にも繋がります。</p>
            </div>
            <div>
              <h2 id="id6">PARKLoTでは無料カウンセリングも行っています！</h2>
              <p>こちらのURLから日程調整も可能です</p>
            </div>
            <a href="https://hub.park-lot.com/free-consulting">
              https://hub.park-lot.com/free-consulting
            </a>
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            <div className="case_study main">
              <CvBtn />
            </div>
            <Author name="moeno" />
            <div className="blog__more-articles">
              <h2 className="text-center">もっと読みたい方へ</h2>
              <ul>
                <li>
                  <a
                    href="/blog/comparison-no-tool"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterキャンペーンで本当にツールは必要なのか？
                    お金をかけずに手動でできる方法は？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/twitter-hashtag-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのハッシュタグキャンペーンとは？効果的なハッシュタグの見つけ方は？基本的なSNSマーケティング戦略を公開
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/examples-of-utilization"
                    target="_blank"
                    rel="noreferrer"
                  >
                    あのブランドはどうやってファンを獲得した?PARKLoT活用事例4選を解説!
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/why-parklot-is-inexpensive"
                    target="_blank"
                    rel="noreferrer"
                  >
                    PARKLoTはどうしてこんなに安くインスタントウィンが実施できるの？皆様の疑問にお答えします！
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/instant-win-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterインスタントウィンとは？リツイートキャンペーンとの違いは？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/present-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのフォロワーが少ない状態でのSNS運用は効果が薄い？プレゼントキャンペーンを実施して一気にフォロワーを獲得しましょう！
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}

export default CookieRegulation
